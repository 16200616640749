.cards-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  // DEFAULT CARD
  .card {
    width: 320px;
    height: 300px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 30px $gray;
    background-color: $white;
    border-radius: 22px;
    margin-top: 20px;
    position: relative;
    .card-icon {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 2px solid $dimgray;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2.5px auto 12.5px auto;
      i {
        font-size: 35px;
      }
      .rotated {
        transform: rotate(90deg);
      }
    }
    .card-image {
      width: 100%;
      height: 270px;
      overflow: hidden;
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      img {
        max-width: 100%;
      }
    }
    .card-title {
      text-align: center;
    }
    .card-text {
      text-align: center;
      margin-top: 12.5px;
    }
  }
  // CARD EVENT
  .card-event {
    height: auto;
    margin-top: 12.5px;
    border-radius: 12.5px;
    display: flex;
    flex-direction: column;
    position: relative;
    .card-image {
      width: 100%;
      height: 260px;
      background-color: #f6f6f6;
      border-radius: 12.5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12.5px;
      }
      @include screen(phone) {
        height: 230px;
      }
    }
    .card-category {
      position: absolute;
      top: 7.5px;
      left: 7.5px;
      .label {
        border-radius: 20px;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .card-price {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: 50px;
      border-bottom-right-radius: 12.5px;
      border-top-left-radius: 5px;
      background-color: $violet-bright;
      position: absolute;
      top: 210px;
      right: 0;
      @include screen(phone) {
        top: 180px;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        color: $white;
      }
    }
    .card-title {
      margin-top: 15px;
      font-size: 18px;
    }
    .card-date {
      margin-top: 5px;
      p {
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  // CARD WORKER
  .card__worker {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 20px;
    padding: 20px;
    border-radius: 12.5px;
    box-shadow: 0px 1px 20px $gray;
    .worker__top--department {
      margin-bottom: 15px;
      span {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          margin: 0 10px 0 10px;
        }
      }
    }
    .card__middle {
      margin: 10px 0 10px 0;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.8;
      }
    }
    .card__bottom {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      margin-top: auto;
      .worker__image {
        width: 60px;
        height: 60px;
        overflow: hidden;
        border-radius: 50%;
        background-color: $gray;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 0 15%;
          background-color: $light-gray;
        }
      }
      .worker__title {
        font-size: 15px;
        font-weight: 500;
        margin-left: 20px;
        color: $dimgray;
      }
    }
    @include screen(phone) {
      margin-top: 0;
      padding: 15px 12.5px;
      .card__bottom {
        .worker__image {
          width: 50px;
          height: 50px;
        }
        .worker__title {
          font-size: 14px;
        }
      }
    }
  }
  .card-action-admin {
    width: 250px;
    height: 300px;
    position: relative;
    background-color: #f0f4ff;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .card-icon {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      background-color: $black;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 30px;
        color: $white;
      }
    }
    .card-icon-link {
      position: absolute;
      right: 20px;
      top: 20px;
      transform: rotate(-45deg);
      i {
        font-size: 30px;
      }
    }
    .card-title {
      margin-top: 12.5px;
      text-align: center;
    }
    &:hover {
      transform: translateY(-7px);
    }
  }
  // CARD EVENT ADMIN
  .card__admin--default {
    margin-bottom: 10px;
    padding: 10px 5px;
    width: 100%;
    min-height: 80px;
    border: 1px solid $gray;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:first-child {
      margin-top: 15px;
    }
    .card__admin--badges {
      margin-top: 35px;
      @include screen(phone) {
        margin-top: 10px;
      }
    }
    .card__admin--default-info {
      display: grid;
      grid-template-columns: 250px minmax(auto, 100px) minmax(auto, 100px) auto;
      gap: 20px;
      margin-bottom: 10px;
      margin-left: 5px;
      .info__block {
        justify-self: start;
        text-transform: uppercase;
        .info__block--title {
          font-weight: 500;
          color: $dimgray;
        }
        .info__block--content {
          margin-top: 5px;
          color: $black;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .card__admin--actions {
      display: flex;
      .btn {
        margin-right: 10px;
      }
    }
    @media screen and (max-width: 1250px) {
      .card__admin--actions {
        flex-direction: column;
        .btn {
          justify-content: center;
          margin-top: 7.5px;
        }
      }
    }
    @include screen(tablet-wide) {
      flex-direction: column;
      align-items: flex-start;
      .card__admin--actions {
        margin-top: 12.5px;
        flex-direction: row;
      }
    }
    @include screen(tablet) {
      .card__admin--default-info {
        grid-template-columns: 1fr 1fr;
      }
    }
    @media screen and (max-width: 500px) {
      .card__admin--actions {
        .btn {
          padding: 0 12.5px;
          font-size: 14px;
        }
      }
    }
    @include screen(phone) {
      .card__admin--default-info {
        grid-template-columns: 1fr 1fr 1fr;
        #title {
          grid-column-end: span 3;
        }
      }
    }
    @include screen(phone-small) {
      .card__admin--actions {
        flex-direction: column;
      }
    }
  }
  // CARD VISITOR
  .card-visitor-admin {
    width: 100%;
    height: auto;
    min-height: 50px;
    background-color: #f0f4ff;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    position: relative;
    .card-delete-button {
      width: 40px;
      height: 40px;
      position: absolute;
      right: -10px;
      top: -10px;
      background-color: #fafafa;
      border-radius: 50%;
      box-shadow: 0px 4px 16px #e6e6e4;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 20px;
        color: $red;
      }
    }
    .card-content {
      width: calc(100% - 50px);
      height: auto;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .card-title {
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
      }
      .card-email,
      .card-phone,
      .card-amount {
        font-size: 14px;
        color: $dimgray;
      }
      .card-check-visitor-btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;
        i {
          font-size: 20px;
        }
        span {
          margin-left: 10px;
          font-family: "Montserrat", sans-serif;
        }
      }
      .visitor-checked {
        i,
        span {
          color: $green;
          text-decoration: line-through;
        }
      }
    }
  }
  // CARD JOB
  .card-job {
    width: 400px;
    height: auto;
    background-color: #f0f4ff;
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    @include screen(phone) {
      width: 100%;
    }
    .card-description {
      margin: 15px 0 15px 0;
    }
    .btn {
      width: 150px;
      margin: auto auto 0 0;
    }
  }
  // CARD PAYMENT
  .card__payment {
    width: 100%;
    margin-top: 15px;
    border-top: 1px solid #f1f1f1;
    .payment__wrapper {
      display: grid;
      grid-template-columns: minmax(250px, 450px) minmax(auto, 200px) 150px;
      gap: 20px;
      align-items: center;
      position: relative;
      padding: 20px;
    }
    .payment__status-line {
      position: absolute;
      left: 0;
      top: -2px;
      width: 4px;
      height: calc(100% + 2px);
    }
    .payment__column {
      display: flex;
    }
    .column--title {
      flex-direction: column;
      .payment__title {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .payment__date {
        font-size: 12px;
        color: $dimgray;
        margin-top: 10px;
        font-weight: 400;
      }
    }
    .column--status {
      flex-direction: row;
      align-items: center;
      .payment__status-text {
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
    .payment__status-icon {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      i {
        font-size: 20px;
      }
    }

    .icon--success {
      background-color: rgba(16, 224, 146, 0.1);
      border: 1px solid $green;
      i {
        color: $green;
      }
    }
    .icon--error {
      background-color: rgba(255, 108, 101, 0.1);
      border: 1px solid $red;
      i {
        color: $red;
      }
    }
    .icon--refunded {
      background-color: rgba(254, 243, 57, 0.1);
      border: 1px solid #fef339;
      i {
        font-size: 17px;
        color: #fef339;
      }
    }
    .payment__price {
      justify-self: flex-end;
      span {
        font-size: 20px;
      }
    }
    .payment__info {
      div {
        display: flex;
        flex-direction: column;
        .payment__refunded-amount,
        .payment__refunded-date,
        .payment__cancel-details {
          font-size: 12px;
          color: $dimgray;
          margin-top: 5px;
          font-weight: 500;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    .payment--success {
      .payment__status-line {
        background-color: $green;
      }
      .payment__status-text {
        &::before {
          content: "Оплачен";
        }
      }
    }
    .payment--refunded {
      .payment__status-line {
        background-color: #fef339;
      }
      .payment__status-text {
        &::before {
          content: "Возращен";
        }
      }
    }
    .payment--error {
      .payment__status-line {
        background-color: $red;
      }
      .payment__status-text {
        &::before {
          content: "Не оплачен";
        }
      }
    }
    @include screen(tablet) {
      .payment__wrapper {
        grid-template-columns: minmax(250px, 1fr);
      }
      .payment__price {
        justify-self: flex-start;
      }
    }
  }
}
