.section__stub--notfound,
.section__stub--server-error {
  .container {
    height: 100vh;
  }
  .stub-wrapper {
    width: 100%;
    height: 100%;
    margin: 40px auto auto auto;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    h2 {
      margin-top: 15px;
    }
    i {
      font-size: 65px;
      color: $red;
    }
  }
}

.section--stub-email-already-confirmed {
  height: 100vh;
  .stub-content {
    margin: 150px auto 0 auto;
    text-align: center;
    .btn {
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px auto 0 auto;
    }
  }
}
