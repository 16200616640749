input {
  border: none;
}

.auth-form {
  width: 300px;
  height: auto;
  position: relative;
  margin: 30px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .input-group {
    width: 100%;
    height: auto;
    margin-top: 10px;
    position: relative;
    .icon-placeholder {
      font-size: 17px;
      position: absolute;
      left: 12.5px;
      top: 21.5px;
      color: #939ca2;
      transition: 0.2s ease-in-out;
    }
    input {
      width: 100%;
      height: 50px;
      background-color: #ececec;
      padding-left: 40px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      font-size: 16px;
      margin-top: 7.5px;
      border-radius: 5px;
      outline: none;
      &::placeholder {
        color: #939ca2;
      }
    }
  }
  .active-input-group {
    .icon-placeholder {
      color: $violet-bright;
    }
  }
  .auth-small-text {
    margin-top: 10px;
    color: $dimgray;
    color: #939ca2;
    font-size: 14px;
    font-weight: 500;
    align-self: flex-start;
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0 25px 0;
    background-color: $violet-bright;
    border-color: $violet-bright;
    border-radius: 5px;
  }
}
