@font-face {
  font-family: IgraSans;
  src: url("../fonts/IgraSans.otf");
  font-display: swap;
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Light.woff2") format("woff2");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
}
