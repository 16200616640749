.amount__selector {
  margin-top: 10px;
  display: flex;
  .amount__selector--button {
    width: 40px;
    height: 40px;
    background-color: $light-gray;
    border-radius: 5px;
    i {
      font-size: 20px;
    }
  }
  .amount__selector--value {
    width: 40px;
    height: 40px;
    background-color: $light-gray;
    margin: 0 5px 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;
  }
}
