button {
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
  border: none;
  justify-content: center;
  outline: none;
  font-family: "Montserrat", sans-serif;
  &:focus {
    outline: none;
  }
}

.btn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 500;
  user-select: none;
  outline: 0;
  border-radius: 8px;
  box-sizing: border-box;
  &:hover {
    transform: translate3d(0px, 0px, 10px);
  }
}

// ANIMATION EFFECT
.hover--circle {
  width: 10px;
  height: 10px;
  // background-color: green;
  position: absolute;
}

.btn-x1 {
  font-size: 15px;
  padding: 15px 30px;
  line-height: 1;
}
.btn-x2 {
  height: 40px;
  padding: 0px 25px;
}
.btn-x3 {
  height: 35px;
  padding: 0px 20px;
}

.btn--round-angles {
  border-radius: 30px;
}

.btn-black {
  border: 1px solid $black;
  color: $black;
  &:hover {
    background-color: $black;
    color: $white;
  }
}

.btn-white {
  border: 2px solid $white;
  color: $white;
  &:hover {
    background-color: $white;
    color: $black;
  }
}

.btn-red {
  border: 2px solid $red;
  color: $red;
  &:hover {
    background-color: $red;
    color: $white;
  }
}

.btn-green {
  border: 2px solid $green;
  color: $green;
  &:hover {
    background-color: $green;
    color: $white;
  }
}

.btn--light {
  color: $third-black;
  box-shadow: 0 1px 4px rgb(225, 225, 225);
}

.btn--gray {
  border: 1px solid $gray;
  color: $black;
  &:hover {
    background-color: $third-black;
    border-color: $third-black;
    color: $white;
  }
}

.btn-green-fill {
  border: 1px solid $green;
  background-color: $green;
  color: $white;
}

.btn-red-fill {
  border: 1px solid $red;
  background-color: $red;
  color: $white;
}

.btn-black-fill {
  border: 1px solid $black;
  background-color: $black;
  color: $white;
}

.btn-white-fill {
  border: 1px solid $white;
  background-color: $white;
  color: $black;
}

.btn--violet-fill {
  border: 1px solid $violet-bright;
  background-color: $violet-bright;
  color: $white;
  font-weight: 500;
  will-change: transform;
}

.btn-icon {
  i {
    font-size: 20px;
    margin: 2.5px 0px 0px 12px;
  }
}

// COMPONENTS/PREVPAGE
.button__history--back {
  button {
    font-size: 16px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    padding: 7.5px 10px 7.5px 0;
    &:hover {
      color: $violet-bright;
    }
    i {
      margin-right: 5px;
    }
  }
}

// COMPONENTS/SOCIAL
.social--links {
  display: flex;
  margin-top: 25px;
  .social__link {
    color: $white;
    font-weight: 500;
    font-size: 15px;
    margin-left: 20px;
    position: relative;
    text-transform: uppercase;
    @include screen(phone) {
      font-size: 13px;
      margin-left: 10px;
    }
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    &::after {
      content: "";
      width: 0;
      height: 1.5px;
      background-color: $white;
      position: absolute;
      bottom: -5px;
      left: 0;
      transition: 0.3s ease-in;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

// COMPONENTS/TAGS
.section__tags-filter {
  margin: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .button-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tags__filter--btn-slide {
    min-width: 45px;
    min-height: 45px;
    position: sticky;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
    i {
      color: $black;
      font-size: 18px;
    }
    &:hover {
      background-color: $third-black;
      border-color: $third-black;
      i {
        color: $white;
      }
    }
    @include screen(tablet) {
      display: none;
    }
  }
  .btn--back {
    margin: 0 10px 0 10px;
  }
  .btn--forward {
    margin: 0 10px 0 10px;
  }
  .tags__filter--wrapper {
    width: auto;
    height: 80px;
    display: flex;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    @include screen(tablet) {
      margin-left: 2.5%;
    }
    .filter-btn {
      margin-left: 12.5px;
      padding: 12.5px 20px;
      border-radius: 30px;
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
      font-weight: 600;
      color: $black;
      white-space: nowrap;
      transition: 0.15s ease-in-out;
      border: 1px solid $gray;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        background-color: $violet-bright;
        border-color: $violet-bright;
        color: $white;
      }
      @include screen(phone) {
        font-size: 14px;
        padding: 12.5px 15px;
      }
    }
    .filter-active {
      background-color: $third-black;
      border-color: $third-black;
      color: $white;
    }
  }
}

// COMPONENTS/TOGGLE
.btn-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 10px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $violet-bright;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $violet-bright;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// COMPONENTS/PAGINATION
.pagination {
  width: auto;
  height: 60px;
  margin: 10px auto 0px auto;
  display: flex;
  align-items: center;
  button {
    width: 45px;
    height: 45px;
    font-size: 16px;
    margin-right: 15px;
    position: relative;
    color: $dimgray;
    border: 1px solid $gray;
    border-radius: 5px;
    transition: 0.2s ease-in;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $violet-bright;
      border-color: $violet-bright;
    }
  }

  .pagination--active {
    color: $white;
    background-color: $violet-bright;
    border-color: $violet-bright;
    &:hover {
      color: $white;
    }
  }

  span {
    font-size: 18px;
    margin-right: 15px;
  }
}
