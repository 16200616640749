.section__header--description {
  .description__header {
    h2 {
      font-size: 35px;
    }
    p {
      margin-top: 15px;
      font-weight: 500;
      line-height: 1.9;
    }
    @include screen(phone) {
      h2 {
        font-size: 30px;
      }
      p {
        line-height: 1.7;
      }
    }
  }
}
