.nav-fixed-top {
  width: 100%;
  height: 65px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  transform: scaleY(1);
  transform-origin: top;
  opacity: 1;
  transition: 0.2s ease-out;
  .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .navbar__sidebar--open {
    height: auto;
    width: 50px;
    height: 50px;
    background-color: $third-black;
    border-radius: 10px;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    display: none;
    .bar {
      height: 1px;
      background-color: $white;
      margin-top: 7px;
      transition: transform 0.2s ease-in-out;
      &:first-child {
        width: 28px;
        margin-top: 0;
      }
      &:nth-child(2) {
        width: 18px;
        transition: 0.2s ease-out;
      }
      &:nth-child(3) {
        width: 23px;
      }
    }
  }
  .sidebar--opened {
    position: relative;
    .bar {
      margin: 0;
      &:first-child {
        transform-origin: bottom;
        transform: rotateZ(45deg) translateX(1px);
        width: 28px;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        width: 28px;
        transform-origin: top;
        transform: rotateZ(-45deg) translateX(1px);
      }
    }
  }
  .navbar__menu,
  .navbar__auth {
    display: flex;
    align-items: center;
    height: 60px;
    .nav__links--logo {
      img {
        height: 50px;
      }
    }
    .navbar--link,
    h6 {
      margin-left: 20px;
      color: $white;
      font-family: IgraSans;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      transition: 0.1s ease-in;
      position: relative;
      letter-spacing: 0.1px;
      &:nth-child(2) {
        margin-left: 40px;
      }
      &::after {
        content: "";
        width: 0;
        height: 2px;
        background-color: $violet-bright;
        position: absolute;
        left: 0;
        bottom: -8px;
      }
      &:hover {
        &::after {
          animation: slideLine 0.8s forwards;
        }
      }
      @keyframes slideLine {
        0% {
          width: 0;
          left: 0;
        }

        65% {
          width: 93%;
          left: 7%;
        }

        85% {
          width: 95%;
        }

        100% {
          left: 0;
          width: 100%;
        }
      }
    }
  }
  .navbar__auth {
    .navbar--link {
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
  .navbar__profile {
    width: 50px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    &:hover {
      .navbar__profile--dropdown {
        display: flex;
      }
    }
    .fa-user {
      margin-right: 7.5px;
    }
    i {
      font-size: 18px;
      color: $white;
    }
    .fa-chevron-down {
      font-size: 14px;
      position: absolute;
      bottom: 23px;
      right: 0;
    }
    .navbar__profile--dropdown {
      width: 200px;
      height: auto;
      position: absolute;
      right: 0;
      top: 55px;
      background-color: $white;
      border-radius: 5px;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
      padding-top: 20px;
      z-index: 5;
      display: none;
      a,
      .logout {
        color: $dimgray;
        font-size: 14px;
        padding: 12.5px 0px 12.5px 15px;
        margin: 0 10px 0 10px;
        align-self: center;
        text-align: left;
        width: calc(100% - 25px);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        border-radius: 7.5px;
        transition: none;
        &:hover {
          background-color: #f4f4f4;
        }
        i {
          width: 22.5px;
          color: $dimgray;
        }
        .fa-user {
          margin-right: 4px;
        }
      }
      .logout {
        margin-bottom: 20px;
      }
    }
  }
  @include screen(tablet-wide) {
    width: auto;
    height: auto;
    background-color: transparent;
    top: 10px;
    right: 10px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;
    .navbar__menu,
    .navbar__profile,
    .navbar__auth {
      display: none;
    }
    .navbar__sidebar--open {
      display: flex;
      flex-direction: column;
    }
    .nav-auth-buttons {
      margin-right: 5px;
    }
  }
}

.nav-main-page {
  transform: scaleY(0);
  opacity: 0;
}
