* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-locale: auto;
  white-space: normal;
}

html {
  font-size: 10px;
  overflow-y: auto;
}

body {
  color: $black;
  font-family: "Montserrat", sans-serif;
}

main {
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: relative;
}

.overlay {
  width: 100%;
  height: 100vh;
  background-color: $black;
  opacity: 0.6;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
}

.margined-navbar {
  margin-top: 60px;
  @include screen(tablet-wide) {
    margin-top: 5px;
  }
}

section {
  width: 100%;
  height: auto;
  position: relative;
  transition: height 0.5s ease-out;
  .content-bar {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    margin: 30px 0px 20px 0px;
    align-items: center;
    .button-group {
      display: flex;
      position: relative;
      button {
        outline: none;
      }
      @media screen and(max-width: 550px) {
        flex-direction: column-reverse;
      }
    }
    @include screen(tablet-wide) {
      margin-top: 15px;
    }
  }
}

.container {
  width: 1270px;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  @include screen(desktop) {
    width: 95%;
  }
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mr-1 {
  margin-right: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.ml-1 {
  margin-left: 10px;
}
