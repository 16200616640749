.notification-container {
  height: auto;
  position: fixed;
  z-index: 10;
}

.top-right {
  top: 40px;
  right: 5px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  @include screen(phone) {
    top: 32px;
  }
}

.notification {
  transition: 0.3s ease;
  position: relative;
  margin-bottom: 10px;
  width: 300px;
  height: 65px;
  border-radius: 5px;
  color: $black;
  display: flex;
  align-items: center;
  position: relative;
  background-color: $white;
  border: 2px solid $light-gray;
  border-left: none;
  .notification-line {
    width: 4px;
    height: 65px;
  }
  .notification-icon-main {
    margin: 0px 10px 0px 10px;
    i {
      font-size: 35px;
      color: $red;
    }
  }
  .notification-content {
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    .notification-title {
      font-size: 14px;
    }
    .notification-message {
      line-height: 1.2;
      font-size: 14px;
    }
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.notification-big-centered {
  width: 400px;
  height: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    i {
      font-size: 25px;
      color: $black;
    }
  }
  .notification-content {
    width: 100%;
    text-align: center;
    .notification-icon {
      margin: 15px 0 10px 0;
      i {
        font-size: 80px;
        color: $green;
      }
    }
    h6,
    p {
      color: $black;
    }
    h6 {
      margin-bottom: 15px;
    }
    p {
      margin: auto;
      width: 290px;
      font-weight: 400;
    }
    .btn {
      padding: 0 50px;
      margin: 20px auto 0px auto;
    }
  }
  @include screen(phone) {
    width: 315px;
  }
}
