footer {
  width: 100%;
  height: auto;
  min-height: 150px;
  margin-top: 15px;
  position: relative;
  background-color: $black;
  display: flex;
  .container {
    flex-direction: column;
    .footer__top {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr auto;
      padding: 20px 0;
      .footer__top--left,
      .footer__top--right {
        height: auto;
        min-height: 55px;
        display: flex;
        flex-direction: column;
        .footer__text--address {
          margin-top: 15px;
          font-size: 25px;
          font-weight: 500;
          color: $white;
          white-space: nowrap;
          .footer__address--link {
            color: $white;
            font-weight: 500;
            font-size: 16px;
            margin-left: 20px;
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 2px;
              background-color: $white;
              position: absolute;
              bottom: -5px;
              left: 0;
              transition: 0.5s ease-out;
            }
            &:hover {
              &::after {
                width: 0;
                left: auto;
                right: 0;
              }
            }
          }
        }
      }
      .footer__top--right {
        justify-self: end;
      }
    }
    .footer__middle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 10px;
      .footer__phones {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        .footer__phone {
          margin-top: 15px;
          color: $white;
          font-weight: 500;
          a {
            color: $white;
            margin-right: 10px;
            &:hover {
              color: $violet-bright;
            }
          }
        }
      }
      .footer__pages {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .footer__link {
          font-size: 16px;
          margin-top: 10px;
          font-weight: 500;
          color: $white;
          &:first-child {
            margin-top: 0;
          }
          &:hover {
            color: $violet-bright;
          }
        }
      }
    }
    .footer__bottom {
      height: 40px;
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      .footer__copyright {
        font-size: 14px;
        color: $dimgray;
        align-self: center;
      }
      .footer__documents {
        justify-self: end;
        align-self: center;
        a {
          font-size: 14px;
          color: $white;
          color: $dimgray;
          &:hover {
            color: $violet-bright;
          }
        }
      }
    }
    @media screen and (max-width: 885px) {
      .footer__top {
        grid-template-columns: 1fr;
        padding-bottom: 0;
        .footer__top--left,
        .footer__top--right {
          justify-self: start;
        }
        .footer__top--right {
          margin-top: 10px;
        }
      }
      .footer__middle {
        grid-template-columns: 1fr;
        .footer__phones,
        .footer__pages {
          justify-self: start;
        }
        .footer__phones,
        .footer__pages {
          margin-top: 15px;
        }
      }
      .footer__bottom {
        grid-template-columns: 1fr;
        height: auto;
        .footer__copyright,
        .footer__documents,
        .footer__documents > a {
          justify-self: start;
          font-size: 12px;
        }
        .footer__documents {
          margin: 15px 0 15px 0;
        }
      }
    }
    @media screen and (max-width: 470px) {
      .footer__top {
        .footer__top--left {
          .footer__text--address {
            font-size: 27px;
            margin-top: 0;
            display: flex;
            flex-wrap: wrap;
            .footer__address--link {
              margin: 10px 0 0 2px;
            }
          }
        }
        .footer__top--right {
          .footer__socials {
            margin-top: 30px;
            .footer__social--link {
              font-size: 14px;
              margin-left: 10px;
            }
          }
        }
      }
      .footer__middle {
        .footer__phones {
          .footer__phone {
            a {
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
        .footer__pages {
          margin-top: 30px;
        }
      }
    }
  }
}
