// PAGES/ADMIN/COMPONENTS/SECTIONHEADERADMIN
.section__admin--header {
  margin-bottom: 10px;
  .section__admin--header-top {
    margin: 30px 0 20px 0;
  }
  .section__admin--header-bottom {
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: auto;
    .section__admin--header-bottom-events {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .btn {
        margin: 0px 7.5px;
        &:last-child {
          margin-right: 0;
        }
      }
      @media screen and (max-width: 570px) {
        .btn {
          margin-top: 10px;
        }
      }
    }
    @include screen(tablet-wide) {
      flex-direction: column;
      align-items: center;
      height: auto;
      .section__admin--header-bottom-events {
        margin-top: 15px;
      }
    }
    @include screen(phone) {
      h4 {
        text-align: center;
      }
    }
  }
}

// PAGES/ADMIN/COMPONENTS/SECTIONSTATADMIN
.section__admin-statistics {
  margin-top: 20px;
  .grid-container {
    width: 100%;
    height: auto;
    min-height: 150px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @include screen(tablet-wide) {
      grid-template-columns: 1fr;
    }
  }
  .admin__statistics--container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    background-color: #fafafa;
    .admin__statistics--stat-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      margin-top: 20px;
    }
    h5 {
      font-weight: 500;
    }
    .admin__statistics--stat {
      height: auto;
      display: flex;
      flex-direction: column;
      span {
        font-size: 30px;
        font-family: IgraSans;
      }
      p {
        margin-top: 10px;
        font-size: 12px;
        color: $dimgray;
        text-transform: uppercase;
      }
    }
    @include screen(phone) {
      padding: 7.5px;
      .admin__statistics--stat {
        h3 {
          font-size: 28px;
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
}

// SectionActions Component (TO DO)
.section--admin-actions {
  .cards-wrapper {
    @include screen(tablet) {
      justify-content: center;
      .card-action-admin {
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 540px) {
      .card-action-admin {
        margin-right: 0;
      }
    }
  }
}

// TODO: REMAKE THIS
.section__admin--lection-edit,
.section__admin--worker-edit {
  .lection-management-form,
  .workers-management-form {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px 50px;
      width: 910px;
      margin-bottom: 20px;
      @include screen(tablet-wide) {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 620px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .textarea-block {
        display: flex;
        flex-direction: column;
        grid-column: span 3;
        font-weight: 500;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        label {
          color: $dimgray;
        }
        .editor {
          height: auto;
          min-height: 300px;
          border: none;
          border-radius: 4px;
          color: $third-black;
          margin-top: 5px;
          resize: none;
          font-size: 16px;
          line-height: 1.5;
        }
        @include screen(tablet-wide) {
          grid-column: span 2;
        }
        @media screen and (max-width: 620px) {
          width: 90%;
        }
      }
    }
    .btn-x1 {
      width: 270px;
      margin-bottom: 15px;
      border-radius: 5px;
      i {
        margin: 0px 10px;
        font-size: 16px;
      }
    }
  }
}

// PAGES/ADMIN/LECTIONS/VISITORS
.section--admin__lection--visitors {
  margin-top: 20px;
  .container {
    min-height: 400px;
  }
  h6 {
    font-weight: 500;
  }
  .cards-wrapper {
    margin-top: 20px;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @include screen(tablet-wide) {
      grid-template-columns: 1fr 1fr;
    }
    @include screen(tablet) {
      grid-template-columns: 1fr;
    }
  }
}

// PAGES/ADMIN/LECTIONS/ARCHIVE
.section--admin__lections--archive {
  margin-top: 25px;
  .container {
    min-height: 400px;
  }
}

// REMAKE TO COMPONENTS
.section__admin--period-selection {
  .chart-form {
    width: 400px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    input {
      width: 180px;
      border-radius: 5px;
      background-color: #f2f2f2;
    }
  }
  .month-picker {
    display: flex;
    button {
      width: 40px;
      height: 40px;
      background-color: #f2f2f2;
      border-radius: 5px;
    }
    .value {
      margin: 0 5px 0 5px;
      padding: 0 15px;
      background-color: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
    }
  }
}

// PAGES/ADMIN/USERS/USERS
.section__admin--users {
  .grid-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  .card__admin--user {
    width: 100%;
    height: auto;
    min-height: 75px;
    border-radius: 6px;
    padding: 0 10px;
    display: grid;
    grid-template-columns: repeat(6, minmax(120px, 1fr));
    gap: 15px;
    @include screen(tablet-wide) {
      grid-template-columns: repeat(3, minmax(120px, 1fr));
      gap: 7.5px;
    }
    @include screen(tablet) {
      grid-template-columns: repeat(2, minmax(120px, 1fr));
    }
    @include screen(phone) {
      grid-template-columns: repeat(1, minmax(120px, 1fr));
      gap: 0;
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
    }
    &:nth-child(2n - 1) {
      background-color: #f0f4ff;
    }
    .card__admin--user-container {
      margin: 12.5px 0 12.5px 0;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      overflow-wrap: break-word;
      .user__container--title {
        font-size: 10px;
        text-transform: uppercase;
        color: $dimgray;
        margin-bottom: 7.5px;
      }
      .user--name {
        text-transform: capitalize;
      }
      .user--confirmed {
        i {
          font-size: 25px;
        }
        .fa-check-circle {
          color: $green;
        }
        .fa-exclamation-circle {
          color: $red;
        }
      }
    }
  }
}

// PAGES/ADMIN/VISITORS/VISITORSTRACKER
.section--admin-visitors-tracker {
  margin: 20px 0 20px 0;
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .action-and-stat-column {
    display: flex;
    flex-direction: column;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    .btn-submit-visitor {
      height: 150px;
      border-radius: 20px;
      background-color: #f0f4ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:focus {
        animation-duration: 0.6s;
        animation-iteration-count: 1;
        animation-name: buttonClick;
      }
      .icon {
        font-size: 45px;
        .fa-child {
          font-size: 40px;
        }
        .fa-running {
          font-size: 45px;
        }
      }
      h6 {
        margin-top: 10px;
        font-size: 17px;
      }
    }
    @keyframes buttonClick {
      0% {
        background-color: #f0f4ff;
        color: $black;
      }
      50% {
        background-color: $green;
        color: $white;
      }
      100% {
        background-color: #f0f4ff;
        color: $black;
      }
    }
  }
}

.section__admin--lections-payments {
  .cards-wrapper {
    flex-direction: column;
  }
}
