.input__block {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  label {
    color: $dimgray;
  }
  small {
    font-size: 10px;
    color: $red;
  }
  @media screen and (max-width: 630px) {
    flex-direction: column;
  }
}

.input__default,
.input__select {
  width: 270px;
  height: 40px;
  background-color: #f4f4f4;
  color: $black;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  margin: 7.5px 0 5px 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  outline: none;
}

.input__dropdown {
  width: 270px;
  height: 40px;
  position: relative;
  margin-top: 7.5px;
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
    color: $third-black;
    font-weight: 500;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }
  .input__dropdown--button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    i {
      transition: 0.2s ease-in-out;
      color: $dimgray;
      cursor: pointer;
    }
  }
  .input__dropdown--selection {
    width: 100%;
    height: 150px;
    position: absolute;
    padding: 10px 0 10px 0;
    margin-top: 10px;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    border-radius: 7.5px;
    top: 100%;
    left: 0;
    z-index: 2;
    display: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    span {
      font-size: 14px;
      margin: 0 5px 0 5px;
      padding: 10px 0px 10px 15px;
      color: $dimgray;
      cursor: pointer;
      border-radius: 7.5px;
      &:hover {
        background-color: #f4f4f4;
      }
    }
  }
}

.input__dropdown--open {
  .input__dropdown--selection {
    display: flex;
  }
  .input__dropdown--button {
    i {
      transform: rotate(180deg);
    }
  }
}

.input__search {
  max-width: 400px;
  height: 45px;
  background-color: #f7f7f7;
  color: $black;
  font-weight: 500;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  i {
    font-size: 20px;
    margin: 0 17.5px 0 17.5px;
    color: $dimgray;
  }
  input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: $dimgray;
    font-family: "Montserrat", sans-serif;
  }
}
