.section--jobs-all {
  .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include screen(desktop) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 850px) {
      grid-template-columns: 1fr;
    }
  }
}

.section--jobs-single {
  .content {
    margin-top: 10px;
    .font-bold {
      font-size: 18px;
    }
    p {
      margin-top: 12px;
      line-height: 1.7;
      &:last-child {
        margin-bottom: 25px;
      }
    }
    li {
      margin: 5px 0 0 15px;
      list-style-type: none;
    }
    a {
      color: $violet-bright;
    }
  }
}
