.sidebar {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  z-index: 10;
  transform: scaleY(0);
  transform-origin: top;
  display: flex;
  flex-direction: column;
  opacity: 0.99;
  .content__wrapper {
    width: 90%;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: 0.3s ease-in;
    .sidebar__logo {
      width: 120px;
    }
    .sidebar__links {
      margin: 20px 0px 20px 0;
      display: flex;
      flex-direction: column;
      .link {
        display: flex;
        align-items: center;
        margin-top: 30px;
        a,
        span {
          width: 100%;
          font-size: 30px;
          font-weight: 500;
          color: $white;
          text-transform: uppercase;
          i {
            margin-right: 7px;
            font-size: inherit;
            color: $white;
          }
        }
        &:last-child {
          margin-top: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .content__wrapper {
      align-items: center;
      .sidebar__logo {
        width: 90px;
      }
      .sidebar__links {
        .link {
          margin-top: 20px;
          a,
          span {
            text-align: center;
            font-size: 22px;
          }
        }
      }
    }
  }
  @include screen(phone-small) {
    .content__wrapper {
      .sidebar__logo {
        display: none;
      }
      .sidebar__links {
        .link {
          margin-top: 17px;
          a,
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
}

.sidebar--opened {
  transform: scaleY(1);
  transition: 0.2s ease-in-out;
  .content__wrapper {
    opacity: 1;
  }
}
