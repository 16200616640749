.slider--fullpage,
.slider--default {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #000000;
  .slides .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
}

.slide-active {
  opacity: 0.7 !important;
}

.slider--fullpage {
  left: 0;
  top: 0;
}

.slider--default {
  right: 0;
  top: 0;
}

// Slider Control Buttons
.btn-slider {
  width: 45px;
  height: 45px;
  background-color: $white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s ease-in;
  z-index: 2;
  outline: none;
  user-select: none;
  i {
    font-size: 22px;
    color: $black;
    transition: 0.15s ease-in-out;
  }
  &:hover {
    background-color: $violet-bright;
    i {
      color: $white;
    }
  }
}
.btn-left-20 {
  left: 135px;
}
.btn-right-20 {
  right: 135px;
}

@include screen(tablet) {
  .btn-left-20 {
    left: 25px;
  }
  .btn-right-20 {
    right: 25px;
  }
}

@media screen and (max-width: 650px) {
  .btn-left-20 {
    display: none;
  }
  .btn-right-20 {
    right: 15px;
  }
}

@include screen(phone) {
  .btn-slider {
    width: 45px;
    height: 45px;
    i {
      font-size: 20px;
    }
  }
}

.slider-swiper {
  .slider-swiper-cards {
    width: auto;
    min-height: 350px;
    height: auto;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 10px 0 20px 0;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .carousel-button {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $white;
    border: 1px solid $gray;
    z-index: 2;
    @include screen(tablet) {
      display: none;
    }
    i {
      font-size: 16px;
      color: $black;
    }
    &:hover {
      background-color: $third-black;
      border-color: $third-black;
      i {
        color: $white;
      }
    }
  }
  .carousel-wrapper-left {
    left: -25px;
    @include screen(tablet-wide) {
      left: 10px;
    }
  }
  .carousel-wrapper-right {
    right: -25px;
    @include screen(tablet-wide) {
      right: 10px;
    }
  }
}
