.loader-spinner-wrapper {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
