.section--coffee-menu {
  .menu--pdf {
    margin: 20px 0 10px 0;
    font-size: 20px;
    font-weight: 500;
    color: $violet-bright;
    i {
      font-size: 25px;
      margin-right: 5px;
    }
  }
}
