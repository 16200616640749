.section--worker-single {
  .profile-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    margin: 20px auto 0 auto;
    .profile-image {
      width: 320px;
      height: auto;
      display: flex;
      flex-direction: column;
      img {
        max-width: 320px;
      }
    }
    .profile-information {
      width: calc(100% - 340px);
      margin: 0 0 0 20px;
      padding: 0px 15px;
      .profile-department {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        @include screen(phone) {
          margin-top: 20px;
        }
      }
      .profile-name {
        text-transform: uppercase;
        font-size: 22px;
        margin-top: 10px;
      }
      .profile-description {
        p {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
        p > span {
          font-family: "Montserrat", sans-serif !important;
          font-size: 16px !important;
        }
        a,
        a > span {
          font-family: "Montserrat", sans-serif !important;
          font-size: 16px !important;
          color: $red !important;
          &:hover {
            text-decoration: underline;
          }
        }
        ul,
        ol {
          margin: 5px 0 0 20px;
          li > span {
            font-size: 16px !important;
            font-family: "Montserrat", sans-serif !important;
          }
        }
      }
    }
    @include screen(tablet) {
      flex-direction: column;
      .profile-information {
        width: 100%;
        margin: 20px 0 20px 0;
        padding: 0;
      }
    }
    @include screen(phone) {
      .profile-image-and-dates {
        width: 100%;
      }
    }
  }
}
