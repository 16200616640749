h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.fix-font {
  margin-left: 1px; // Fix IgraSans CAPS Font Behavior
}

h1 {
  font-size: 4.5rem;
  @include screen(tablet) {
    font-size: 3.5rem;
  }
}
h2 {
  font-size: 4rem;
}
h3 {
  font-size: 3.5rem;
}
h4 {
  font-size: 3rem;
}
h5 {
  font-size: 2.5rem;
}
h6 {
  font-size: 2rem;
}
p {
  font-size: 1.7rem;
  line-height: 1.7;
  font-family: "Montserrat", sans-serif;
}
a {
  font-size: 1.6rem;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  font-family: "Montserrat", sans-serif;
  color: $black;
}
li {
  font-size: 1.6rem;
  line-height: 1.7;
}

.text-center {
  text-align: center;
}

.font-white {
  color: $white;
}

.font-red {
  color: $red;
}

.font-violet {
  color: $violet-bright;
}

.font-uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: 600;
}
