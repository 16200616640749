.admin__chart--wrapper {
  margin: 20px 0 20px 0;
  .chart {
    height: auto;
    .chart-title {
      font-size: 25px;
      font-weight: 500;
      margin-top: 10px;
    }
    .chart-wrapper {
      margin-top: 20px;
    }
    .bar-chart,
    .line-chart {
      min-height: 400px;
    }
    .doughnut-chart {
      min-height: 550px;
    }
  }
}
