.labels__wrapper {
  position: absolute;
  top: 7.5px;
  display: flex;
  flex-wrap: wrap;
  @include screen(phone) {
    position: relative;
    left: 0;
    top: 0;
  }
}
.label {
  margin: 0 0 0 5px;
  padding: 5px 15px;
  color: $white;
  border-radius: 6.5px;
  font-weight: 500;
  &:first-child {
    margin-left: 0;
    @include screen(phone) {
      margin-left: 5px;
    }
  }
}
.label--small {
  font-size: 10px;
  padding: 5px 10px;
}
.label--medium {
  font-size: 13px;
  padding: 5px 12.5px;
}
.label--big {
  font-size: 16px;
}
.label--black {
  background-color: $third-black;
}
.label--red {
  background-color: $red;
}
.label--yellow {
  color: $dimgray;
  background-color: #fef339;
}
.label--dimgray {
  background-color: $dimgray;
}
.label--violet-light {
  background-color: $violet;
}
.label--violet {
  background-color: $violet-bright;
}
