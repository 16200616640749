// COMPONENTS
@import "./components/_AmountSelector";

.section--lectorium-page__all-lections {
  margin-bottom: 25px;
  .content-bar {
    margin-top: 10px;
    justify-content: space-between;
    .current__filter--lections {
      font-size: 25px;
      font-weight: 600;
    }
    @include screen(phone) {
      margin-top: 0;
      flex-direction: column-reverse;
    }
  }
  .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    @media screen and (max-width: 1130px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }
    .card-lection {
      margin-top: 15px;
    }
  }
}

.section--lectorium-page__single-event {
  .container {
    min-height: 400px;
  }
  .single-event__wrapper {
    width: 1000px;
    height: auto;
    margin: 10px auto 40px auto;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 1050px) {
      width: 100%;
    }
    @include screen(phone) {
      margin-top: 0;
    }
    .single-event__header--subtitle {
      color: $dimgray;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      margin: 7.5px 0px 5px 0px;
      @media screen and (max-width: 830px) {
        margin-top: 12.5px;
      }
    }
    .single-event__header--warning {
      font-size: 18px;
      font-weight: 500;
    }
    .single-event__yandex-widget {
      position: fixed;
      width: 95%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .single-event__header {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      position: relative;
      @media screen and (max-width: 830px) {
        grid-template-columns: 1fr;
      }
      .single-event__header--left {
        min-width: 420px;
        max-height: 500px;
        height: auto;
        position: relative;
        @media screen and (max-width: 830px) {
          min-width: 300px;
          width: 80%;
          height: 400px;
        }
        @include screen(tablet) {
          width: 100%;
        }
        @include screen(phone) {
          height: 330px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7.5px;
        }
      }
      .single-event__header--right {
        min-height: 450px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 830px) {
          min-height: 350px;
        }
        .single-event__header--content-top {
          display: flex;
          flex-direction: column;
          .single-event__header--content-top-title {
            margin-top: 7.5px;
            h1 {
              font-size: 25px;
              @include screen(phone) {
                font-size: 22px;
              }
              @include screen(phone-small) {
                font-size: 20px;
              }
            }
          }
          .single-event__header--content-top-tags {
            display: flex;
            @include screen(phone) {
              flex-direction: column;
              .label {
                align-self: flex-start;
                margin: 5px 0 0 0;
                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }
        .single-event__header--content-middle {
          display: flex;
          flex-direction: column;
          .single-event__header--content-middle-date,
          .single-event__header--content-middle-amount,
          .single-event__header--content-middle-price {
            font-size: 20px;
            font-weight: 500;
          }
          .single-event__header--content-middle-amount,
          .single-event__header--content-middle-price {
            font-weight: 600;
          }
          .single-event__header--content-middle-amount {
            color: $violet-bright;
          }
          .single-event__header--content-middle-price {
            color: $violet-bright;
            button {
              margin-top: 10px;
              border-radius: 10px;
              font-size: 16px;
              font-family: "Montserrat", sans-serif;
            }
          }
        }
        .single-event__header--content-bottom {
          margin-top: 10px;
          .btn {
            border-radius: 7.5px;
          }
          .header__content--bottom-unauthorized {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .header__content--bottom-subscribe-actions {
            display: flex;
            align-items: center;
            p {
              margin: 0 15px 0 15px;
            }
            .action__buy {
              display: flex;
              align-items: center;
              p {
                margin-right: 0;
                color: $dimgray;
              }
            }
            @media screen and (max-width: 1020px) {
              flex-direction: column;
              align-items: flex-start;
              p {
                margin: 10px 0 0 0;
                display: none;
              }
              .btn {
                width: 230px;
              }
            }
          }
        }
      }
    }
    .single-event__description {
      margin-top: 15px;
      .description__lecturer {
        margin: 15px 0 15px 0;
        h2 {
          font-size: 20px;
          @include screen(phone) {
            font-size: 18px;
          }
        }
      }
      .single-event__description--text {
        p {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
        p > span {
          font-size: 16px !important;
        }
        span > br,
        p > br {
          display: none;
        }
        a,
        a > span {
          color: $violet-bright !important;
          &:hover {
            text-decoration: underline;
          }
        }
        li {
          margin: 7.5px 0 0 25px !important;
        }
      }
    }
  }
}
