.editor__tip-tap {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  border: 3px solid $black;
  border-radius: 10px;
  margin-top: 10px;
}

.editor__textarea {
  width: 100%;
  padding: 20px 10px 20px 10px;
  outline: none;
  .ProseMirror {
    outline: none;
  }
  li,
  ol {
    margin-left: 20px;
  }
  p {
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    color: $violet-bright;
  }
}

.editor__menu {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 3px solid $black;
  padding: 10px 0;
  align-items: center;
  button {
    padding: 5px 7.5px;
    margin: 0 2.5px 0 2.5px;
    border-radius: 7.5px;
    &:hover {
      background-color: $black;
      i {
        color: $white;
      }
    }
  }
  .is-active {
    background-color: $violet-bright;
    i {
      color: $white;
    }
  }
  .editor__divider {
    width: 2px;
    height: 20px;
    margin: 0 3px 0 3px;
    background-color: $gray;
  }
}
