.section--welcome-page__hero {
  height: 100vh;
  overflow: hidden;
  .portal-wrapper {
    width: 250px;
    position: absolute;
    top: 0;
    z-index: 2;
    @media screen and (max-width: 815px) and (orientation: landscape) {
      display: none;
    }
  }
  .global-portal-left {
    left: 0;
    width: 0;
    height: 0;
    transition: transform 250ms ease-in;
    will-change: transform;
    &::after {
      content: "";
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      border-top: 100vh solid $black;
      border-right: 250px solid transparent;
    }
    img {
      width: 80px;
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 25px;
      transition: 0.1s ease-in;
    }
    @media screen and (max-width: 730px) {
      &::after {
        border-right-width: 100vw;
        border-top-width: 130px;
      }
      img {
        display: none;
      }
    }
  }
  .global-portal-right {
    right: 0;
    width: 0;
    height: 0;
    transition: transform 250ms ease-out;
    will-change: transform;
    &::after {
      content: "";
      height: 0;
      position: absolute;
      right: 0;
      top: 0;
      border-bottom: 100vh solid $black;
      border-left: 250px solid transparent;
    }
    .portal-links {
      width: 180px;
      height: 150px;
      position: absolute;
      right: 20px;
      bottom: calc(-100vh - 20px);
      z-index: 2;
      transition: 0.1s ease-out;
      h6 {
        font-family: IgraSans;
        font-size: 16px;
        text-transform: uppercase;
        text-align: right;
        display: flex;
        flex-direction: column;
        color: $white;
        letter-spacing: 0.1px;
        font-weight: 400;
        cursor: pointer;
        span {
          margin-top: 12.5px;
          &:hover {
            color: $violet-bright;
          }
        }
      }
    }
    @media screen and (max-width: 730px) {
      &::after {
        border-left-width: 100vw;
        border-bottom-width: 130px;
        top: calc(100vh - 130px);
      }
      .portal-links {
        display: none;
      }
    }
  }
  .portal-left-animated {
    transform: scaleX(0);
    img {
      opacity: 0;
    }
    @media screen and (max-width: 730px) {
      transform-origin: top;
      transform: scaleY(0);
    }
  }
  .portal-right-animated {
    transform: scaleX(0);
    .portal-links {
      opacity: 0;
    }
  }
  .container {
    height: 100vh;
  }
  .title__hero {
    margin: auto auto auto 0px;
    h1 {
      font-size: 6rem;
      transition: 0.15s ease-in-out;
      font-family: IgraSans;
      text-transform: uppercase;
      color: $white;
      letter-spacing: 2px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      span {
        &:hover {
          color: $violet-bright;
        }
      }
    }
    @include screen(desktop-wide) {
      margin-left: 200px;
      h1 {
        font-size: 5.5rem;
      }
    }
    @media screen and (max-width: 650px) {
      margin-top: auto;
      margin-left: 40px;
      h1 {
        font-size: 5rem;
      }
    }
    @media screen and (max-width: 450px) {
      margin-left: 15px;
      h1 {
        font-size: 4rem;
      }
    }
  }
}

.section--welcome-page__header {
  width: 100%;
  height: auto;
  display: flex;
  margin: 20px 0 10px 0;
  flex-direction: column;
  h3 {
    margin-bottom: 15px;
  }
  p {
    font-size: 17px;
    font-weight: 500;
    line-height: 2;
    &:first-child {
      margin-top: 15px;
    }
  }
  .font-big {
    font-size: 20px;
    font-weight: 600;
  }
  .btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-self: start;
  }
  @include screen(tablet) {
    p {
      font-size: 16px;
    }
  }
  @include screen(phone) {
    p {
      line-height: 1.7;
    }
  }
}

.section--welcome-page__consultants {
  .cards-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    .button__wrapper {
      justify-self: center;
      grid-column: span 3;
    }
    @include screen(desktop) {
      grid-template-columns: 1fr 1fr;
      .button__wrapper {
        grid-column: span 2;
      }
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: 1fr;
      .card__worker {
        width: 450px;
        justify-self: left;
      }
      .button__wrapper {
        grid-column: span 1;
      }
    }
    @include screen(phone) {
      .card__worker {
        width: 100%;
      }
    }
  }
}

.section--welcome-page__coffee {
  margin: 0 0 20px 0;
  height: auto;
  .cards-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include screen(tablet-wide) {
      margin-top: 0;
      grid-template-columns: 1fr 1fr;
      .card__coffee--welcome {
        margin-top: 20px;
        justify-self: center;
      }
    }
    @media screen and (max-width: 655px) {
      grid-template-columns: 1fr;
      .card__coffee--welcome {
        width: 100%;
        height: 340px;
      }
    }
  }
  .card__coffee--welcome {
    width: 300px;
    height: 380px;
    padding: 30px;
    background-color: #543f3d;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 10px;
    .card__icon {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #40312f;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-size: 30px;
        color: $white;
      }
    }
    .card__title {
      margin: 20px 0 20px 0;
      color: $white;
    }
    .card__description {
      font-weight: 500;
      font-size: 15px;
      color: $white;
    }
    .btn {
      width: 100%;
      background-color: #40312f;
      border: none;
      display: flex;
      justify-content: center;
      font-weight: 700;
      color: $white;
      margin-top: auto;
    }
    @include screen(phone) {
      img {
        width: 270px;
      }
    }
  }
}

.section--welcome-page__lectorium-closest {
  margin-top: 25px;
  .cards-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    @media screen and (max-width: 1130px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      justify-items: center;
      gap: 15px;
    }
  }
  .card-event {
    .card-image {
      height: 230px;
    }
    .card-price {
      top: 180px;
    }
    .btn-next {
      max-width: 270px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin: 120px auto 0 0;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      i {
        margin-left: 10px;
        font-size: 18px;
      }
    }
    @include screen(phone) {
      width: 100%;
      margin-right: 0;
    }
  }
}
