.section--profile__actions-info {
  .container {
    min-height: 100px;
  }
  .profile__topbar {
    margin-top: 20px;
    font-weight: 500;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @include screen(tablet) {
      flex-direction: column-reverse;
    }
    .profile__actions {
      display: flex;
      flex-wrap: nowrap;
      .button__action {
        margin-left: 20px;
        padding: 12.5px 25px;
        background-color: #f4f4f4;
        border-radius: 12.5px;
        display: flex;
        i {
          color: $black;
          margin-right: 10px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          background-color: #f1f1f1;
        }
      }

      .button--active {
        background-color: $violet-bright;
        color: $white;
        i {
          color: $white;
        }
        &:hover {
          background-color: $violet-bright;
        }
      }
      @include screen(tablet) {
        margin-top: 25px;
      }
    }
    .profile--information {
      width: 280px;
      height: 70px;
      border-radius: 12.5px;
      box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
      text-align: right;
      display: flex;
      z-index: 1;
      .profile--text {
        margin: auto 10px auto auto;
        .profile--name {
          color: $black;
          text-transform: capitalize;
        }
        .profile--email {
          width: 170px;
          font-size: 12px;
          color: $dimgray;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .profile--avatar {
        margin: auto auto auto 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $violet-bright;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 20px;
          color: $white;
        }
      }
    }
  }
}

.section--profile__incoming-events {
  margin-top: 25px;
  .container {
    min-height: 400px;
  }
  .incoming__events--title {
    @include screen(phone-small) {
      text-align: center;
      font-size: 18px;
    }
  }
  .cards-wrapper {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    @media screen and (max-width: 1130px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }
    .card-lection {
      margin-top: 15px;
    }
  }
}

.section__profile--payments {
  margin-top: 25px;
  .container {
    min-height: 400px;
  }
  .cards-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
