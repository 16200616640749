$white: #ffffff;
$black: #1a1c21;
$secondary-black: #1e2025;
$third-black: #26292e;

$khaki: #b8bc74;
$gold: #ffeec3;
$yellow: #fef339;
$red: #ff6c65;
$pink: #dba6c0;
$green: #10e092;
$dark-green: #85bc72;
$violet: #999aca;
$violet-bright: #6c63ff;

$gray: #e6e6e4;
$dimgray: #525558;
$light-gray: #f2f2f2;
