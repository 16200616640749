// LOCAL COMPONENTS STYLES
@import "./components/_Menu";

.section__coffee--classic-cards,
.section__coffee--alternate-cards {
  margin-top: 25px;
  h3 {
    font-weight: 500;
    font-size: 25px;
  }
  .cards-wrapper {
    justify-content: space-between;
  }
}

.section--coffee-team-cards {
  margin-top: 15px;
  .card__worker--coffee {
    min-width: 270px;
    width: 270px;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 25px;
    border-radius: 12.5px;
    .worker__top--department {
      position: absolute;
      top: 7.5px;
      left: 7.5px;
      padding: 5px 12.5px;
      border-radius: 20px;
      background-color: $third-black;
      p {
        font-size: 12px;
        font-weight: 500;
        color: $white;
      }
    }
    .card__worker--image {
      width: 100%;
      height: 290px;
      overflow: hidden;
      border-radius: 7.5px;
      background-color: $gray;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: 0 15%;
        background-color: $light-gray;
      }
    }
    .worker__title {
      margin-top: 12.5px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

// CARD COFFEE CLASSIC COMPONENT
.card__coffee--classic {
  margin-top: 25px;
  width: 320px;
  height: auto;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 12.5px;
  &:hover {
    .coffee__info--hidden {
      opacity: 1;
    }
  }
  .coffee__image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    img {
      max-width: 100%;
      height: 300px;
      object-fit: cover;
    }
  }
  .coffee__type {
    position: absolute;
    left: 7.5px;
    top: 7.5px;
    background-color: #c18e61;
    color: $white;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 12.5px;
    border-radius: 20px;
    z-index: 2;
  }
  .coffee__title {
    font-size: 18px;
    font-weight: 500;
  }
  .coffee__taste {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
  }
  .coffee__info--hidden {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    padding: 50px 10px 30px 10px;
    background-color: #ffeec3;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-in;
    opacity: 0;
    .coffee__info--title {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
    .coffee__info--bars {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      .bar__wrapper {
        width: 100%;
        height: 45px;
        margin-top: 12.5px;
        .bar__wrapper--title {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 500;
        }
        .bar__wrapper--progress {
          width: 100%;
          height: 4px;
          background-color: #c18e61;
          margin-top: 7.5px;
          position: relative;
          .progress__value {
            height: 4px;
            background-color: $black;
          }
        }
      }
    }
    .coffee__info--description {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      span {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

// CARD COFFEE ALTERNATE COMPONENT
.card-coffee-alternate {
  width: 320px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  .card-image {
    margin: 0 auto 0 auto;
    overflow: hidden;
    img {
      width: 300px;
      object-fit: cover;
    }
  }
  .card-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 22px;
    height: 55px;
  }
  .card-description {
    margin: 5px 0 5px 0;
    padding-right: 20px;
    font-size: 17px;
    font-weight: 500;
  }
  .card-progress-bars {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    .bar-wrapper {
      height: 40px;
      display: flex;
      flex-direction: column;
      margin-top: 12.5px;
      .bar-title {
        font-size: 12px;
        text-transform: uppercase;
      }
      .bar-progress {
        width: 100%;
        height: 4px;
        margin-top: 7.5px;
        background-color: #c18e61;
        position: relative;
      }
      .bar-progess-value {
        width: 50%;
        height: 4px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $black;
      }
    }
  }
  .bars-grid-2fr {
    grid-template-columns: 1fr 1fr;
  }
  .card-info {
    font-weight: 500;
    margin-top: 2.5px;
    font-size: 13px;
    &:first-child {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 405px) {
    .card-coffee {
      width: 300px;
    }
  }
}
