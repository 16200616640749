.section--auth-login,
.section--auth-register,
.section--auth-forgot-password,
.section--auth-create-new-password {
  .container {
    min-height: 80vh;
  }
  .content-bar {
    margin-top: 40px;
  }
  .auth-container {
    width: 420px;
    height: auto;
    min-height: 480px;
    margin: 30px auto 40px auto;
    background-color: #f8fafc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12.5px;
    .column {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
    .column-header {
      width: 300px;
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      .icon {
        font-size: 75px;
        color: $violet-bright;
      }
      .auth-links {
        margin-top: 30px;
        a,
        p {
          text-transform: uppercase;
          font-weight: 600;
          color: $violet-bright;
          opacity: 0.7;
          margin-right: 20px;
          position: relative;
          &:last-child {
            margin-right: 0;
          }
        }
        .active-auth-link {
          opacity: 1;
          &::after {
            content: "";
            width: 98%;
            height: 3px;
            background-color: $violet-bright;
            position: absolute;
            left: 1%;
            bottom: -7.5px;
            border-radius: 10px;
          }
        }
      }
    }
    @include screen(phone) {
      margin-top: 25px;
      width: 340px;
      height: 530px;
    }
    @include screen(phone-small) {
      width: 310px;
    }
  }
}
